import StoreState from "@Js/Store/StoreState";

import { MenuAction } from "@Js/Actions/menuActions";

import { SET_IS_OPEN } from "@Js/ActionTypes/menuActionTypes";

type MenuState = StoreState["menu"];

const initialState = {
    isOpen: false
};

const menuReducer = (state: MenuState = initialState, action: MenuAction) => {
    switch (action.type) {
        case SET_IS_OPEN: {
            return { ...state, isOpen: action.payload.isOpen };
        }

        default: {
            return state;
        }
    }
};

export default menuReducer;
