import Img from "gatsby-image";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FixedObject } from "gatsby-image";
import { Helmet } from "react-helmet";
import { PropsWithChildren } from "react";

import { Fragment } from "react";

import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import Link from "@Js/Component/Link/Link";
import Menu from "@Js/Component/Menu/Menu";

import { useLocation } from "@reach/router";

import classNames from "@Css/Component/Layout/Layout.module.scss";

type File = {
    childImageSharp: {
        fixed: FixedObject;
    };
};

type Options = {
    options: {
        address_city: string;
        address_postal_code: string;
        address_street: string;
        contact_email: string;
        contact_phone: string;
        contact_facebook: string;
        contact_linkedin: string;
        contact_twitter: string;
    };
};

export type LayoutProps = Readonly<PropsWithChildren<{}>>;

const Layout = (props: LayoutProps) => {
    const {
        children
    } = props;

    const result = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fixed(width: 200) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            wordpressAcfOptions {
                options {
                    address_city
                    address_postal_code
                    address_street
                    contact_email
                    contact_phone
                    contact_facebook
                    contact_linkedin
                    contact_twitter
                }
            }
        }
    `);

    const location = useLocation();
    const options = result.wordpressAcfOptions as Options;
    const file = result.file as File;

    return (
        <Fragment>
            <Helmet>
                <meta charSet="UTF-8" />

                <meta content="initial-scale=1.0, maximum-scale=1.0, user-scalable=no, width=device-width" name="viewport" />
                <meta content="index, follow" name="robots" />

                <title>Dutch TechZone | De regio van technisch vakmanschap voor de maakindustrie</title>
            </Helmet>
            <header>
                <Link className={ classNames.logo } to="/">
                    <Img fixed={ file.childImageSharp.fixed } />
                </Link>
                <Menu />
            </header>
            { children }
            <footer className={ classNames.footer }>
                <div className={ classNames.container }>
                    <div className={ classNames.left }>
                        <strong className={ classNames.title }>Dutch TechZone</strong><br />
                        <span>{ options.options.address_street }</span><br />
                        <span>{ options.options.address_postal_code } { options.options.address_city }</span>
                    </div>
                    <div className={ classNames.right }>
                        <strong className={ classNames.title }>Contact</strong><br />
                        <a
                            className={ classNames.link }
                            href={ "tel: " + options.options.contact_phone }>
                            { options.options.contact_phone }
                        </a><br />
                        <a
                            className={ classNames.link }
                            href={ "mailto: " + options.options.contact_email }
                        >
                            { options.options.contact_email }
                        </a>
                    </div>
                    <div className={ classNames.bottom }>
                        {['/story','/nieuwsbrief','/contact'].indexOf(location.pathname) == -1 &&
                        <Link className={classNames.button} to="/contact">Contact</Link>
                        }
                        <div className={ classNames.socialMedia }>
                            <a className={ classNames.link } href={ options.options.contact_facebook }>
                                <FontAwesomeIcon icon={ faFacebookF as unknown as "facebook-f" } />
                            </a>
                            <a className={ classNames.link } href={ options.options.contact_twitter }>
                                <FontAwesomeIcon icon={ faTwitter as unknown as "twitter" } />
                            </a>
                            <a className={ classNames.link } href={ options.options.contact_linkedin }>
                                <FontAwesomeIcon icon={ faLinkedinIn as unknown as "linkedin-in" } />
                            </a>
                        </div>
                        <Link className={ classNames.link } to="/privacy">Privacy statement</Link>
                        <span className={ classNames.divider }>|</span>
                        <Link className={ classNames.link } to="/algemene-voorwaarden">Algemene voorwaarden</Link>
                        <span className={ classNames.divider }>|</span>
                        <Link className={ classNames.link } to="/partners">Partners</Link><br />
                        <span>Website ontworpen en ontwikkeld door Baaz & Co en X-Interactive</span>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
};

export default Layout;
