// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-js-component-page-404-tsx": () => import("./../src/js/Component/Page/404.tsx" /* webpackChunkName: "component---src-js-component-page-404-tsx" */),
  "component---src-js-component-page-contact-tsx": () => import("./../src/js/Component/Page/contact.tsx" /* webpackChunkName: "component---src-js-component-page-contact-tsx" */),
  "component---src-js-component-page-index-tsx": () => import("./../src/js/Component/Page/index.tsx" /* webpackChunkName: "component---src-js-component-page-index-tsx" */),
  "component---src-js-component-page-nieuwsbrief-tsx": () => import("./../src/js/Component/Page/nieuwsbrief.tsx" /* webpackChunkName: "component---src-js-component-page-nieuwsbrief-tsx" */),
  "component---src-js-component-page-story-tsx": () => import("./../src/js/Component/Page/story.tsx" /* webpackChunkName: "component---src-js-component-page-story-tsx" */),
  "component---src-js-component-template-category-tsx": () => import("./../src/js/Component/Template/Category.tsx" /* webpackChunkName: "component---src-js-component-template-category-tsx" */),
  "component---src-js-component-template-event-tsx": () => import("./../src/js/Component/Template/Event.tsx" /* webpackChunkName: "component---src-js-component-template-event-tsx" */),
  "component---src-js-component-template-events-tsx": () => import("./../src/js/Component/Template/Events.tsx" /* webpackChunkName: "component---src-js-component-template-events-tsx" */),
  "component---src-js-component-template-page-tsx": () => import("./../src/js/Component/Template/Page.tsx" /* webpackChunkName: "component---src-js-component-template-page-tsx" */),
  "component---src-js-component-template-post-tsx": () => import("./../src/js/Component/Template/Post.tsx" /* webpackChunkName: "component---src-js-component-template-post-tsx" */)
}

