// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";

import { GatsbyLinkProps } from "gatsby-link";

const Link = (props: GatsbyLinkProps<{}>) => {
    return (
        <AniLink direction="" duration={ 0.70 } hex="#c22b22" paintDrip { ...props } />
    );
};

export default Link;
