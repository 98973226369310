import React from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link } from "gatsby";
import { MouseEvent } from "react";
import { Transition } from "react-spring/renderprops";

import { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { config } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { faChartLine } from "@fortawesome/pro-regular-svg-icons";
import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faHomeLg } from "@fortawesome/pro-regular-svg-icons";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { useState } from "react";

import "@fortawesome/fontawesome-svg-core/styles.css";

import AniLink from "@Js/Component/Link/Link";
import Backdrop from "@Js/Component/Backdrop/Backdrop";

import useMediaQuery from "@Js/Hook/useMediaQuery";

import classNames from "@Css/Component/Menu/Menu.module.scss";

config.autoAddCss = false;

type MenuItemEdge = {
    node: {
        classes: string[];
        title: string;
        type: string;
        url: string;
        wordpress_id: number;
        wordpress_parent: number;
    };
};

const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const isMobile = useMediaQuery("(max-width: 1023px)");

    const result = useStaticQuery(graphql`
        query {
            allWordpressDtzMenuItems(sort: { fields: order }) {
                edges {
                    node {
                        classes
                        title
                        type
                        url
                        wordpress_id
                        wordpress_parent
                    }
                }
            }
        }
    `);

    const menuItemEdges = result.allWordpressDtzMenuItems.edges as MenuItemEdge[];

    const onButtonClick = () => {
        if (isOpen) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }
    };

    const renderAllMenuItemEdgesRecursively = (wordpressParent: number) => {
        const filterCallback = (edge: MenuItemEdge) => {
            return edge.node.wordpress_parent == wordpressParent;
        };

        return menuItemEdges.filter(filterCallback).map((edge) => {
            const allMenuItemEdges = renderAllMenuItemEdgesRecursively(edge.node.wordpress_id);

            let icon: IconProp | null = null;

            if (edge.node.classes.includes("home")) {
                icon = faHomeLg;
            }

            if (edge.node.classes.includes("the-zone")) {
                icon = faChartNetwork;
            }

            if (edge.node.classes.includes("storiez")) {
                icon = faNewspaper;
            }

            if (edge.node.classes.includes("numberz")) {
                icon = faChartLine;
            }

            if (edge.node.classes.includes("contact")) {
                icon = faEnvelope;
            }

            const onLinkClick = (event: MouseEvent) => {
                if (allMenuItemEdges.length > 0) {
                    event.preventDefault();
                }

                if (allMenuItemEdges.length == 0) {
                    setIsOpen(false);
                }
            };

            return (
                <li className={ classNames.listItem } key={ edge.node.wordpress_id }>
                    { allMenuItemEdges.length == 0 &&
                        <AniLink
                            activeClassName={ classNames.active }
                            className={ classNames.link }
                            onClick={ onLinkClick }
                            partiallyActive={ !(edge.node.url == "/") }
                            to={ edge.node.url }
                        >
                            { icon && <FontAwesomeIcon className={ classNames.linkIcon } icon={ icon } /> }
                            <span className={ classNames.linkText }>{ edge.node.title }</span>
                        </AniLink>
                    }
                    { allMenuItemEdges.length > 0 &&
                        <Link
                            activeClassName={ classNames.active }
                            className={ classNames.link }
                            onClick={ onLinkClick }
                            partiallyActive={ !(edge.node.url == "/") }
                            to={ edge.node.url }
                        >
                            { icon && <FontAwesomeIcon className={ classNames.linkIcon } icon={ icon } /> }
                            <span className={ classNames.linkText }>{ edge.node.title }</span>
                        </Link>
                    }
                    { allMenuItemEdges.length > 0 &&
                        <ul className={ classNames.list }>
                            { allMenuItemEdges }
                        </ul>
                    }
                </li>
            );
        });
    };

    return (
        <Fragment>
            { isMobile &&
                <Transition enter={{ opacity: 1 }} from={{ opacity: 0 }} items={ isOpen } leave={{ opacity: 0 }}>
                    { (item) => (props) => (item &&
                        <Backdrop style={ props } />
                    ) }
                </Transition>
            }
            { isMobile &&
                <Transition
                    enter={{ opacity: 1, transform: "translateX(0px)" }}
                    from={{ opacity: 0, transform: "translateX(80px)" }}
                    items={ isOpen }
                    leave={{ opacity: 0, transform: "translateX(80px)" }}
                >
                    { (item) => (props) => (
                        <Fragment>
                            { item &&
                                <nav className={ classNames.menu }>
                                    <button
                                        className={ classNames.button }
                                        onClick={ onButtonClick }
                                        style={ props }
                                        type="button"
                                    >
                                        <FontAwesomeIcon className={ classNames.buttonIcon } icon={ faTimes } />
                                    </button>
                                    <ul className={ classNames.list } style={ props }>
                                        { renderAllMenuItemEdgesRecursively(0) }
                                    </ul>
                                </nav>
                            }
                            { !(item) &&
                                <button
                                    className={ classNames.button }
                                    onClick={ onButtonClick }
                                    style={ props }
                                    type="button"
                                >
                                    <FontAwesomeIcon className={ classNames.buttonIcon } icon={ faBars } />
                                </button>
                            }
                        </Fragment>
                    ) }
                </Transition>
            }
            { !(isMobile) &&
                <nav className={ classNames.menu }>
                    <ul className={ classNames.list }>
                        { renderAllMenuItemEdgesRecursively(0) }
                    </ul>
                </nav>
            }
        </Fragment>
    );
};

export default Menu;
