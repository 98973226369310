import React from "react"

import { ReactNode } from "react"

import Layout from "@Js/Component/Layout/Layout";

type PageWrapperProps = Readonly<{
    element: ReactNode
}>;

const PageWrapper = (props: PageWrapperProps) => {
    const {
        element
    } = props;

    return (
        <Layout>
            { element }
        </Layout>
    );
}

export default PageWrapper;
