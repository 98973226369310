import { anchorate } from "anchorate";

import PageWrapper from "./src/js/Component/Wrapper/PageWrapper/PageWrapper";
import RootWrapper from "./src/js/Component/Wrapper/RootWrapper/RootWrapper";

export const wrapPageElement = PageWrapper;
export const wrapRootElement = RootWrapper;

export const onRouteUpdate = () => {
    anchorate();
};
