import React from "react";

import { HTMLProps } from "react";

import clsx from "clsx";

import { useEffect } from "react";

import classNames from "@Css/Component/Backdrop/Backdrop.module.scss";

export type BackdropProps = Readonly<HTMLProps<HTMLDivElement>>;

const Backdrop = (props: BackdropProps) => {
    const {
        className,
        ...restProps
    } = props;

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    });

    return <div { ...restProps } className={ clsx(classNames.backdrop, className) } />;
};

export default Backdrop;
