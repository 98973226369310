import { useEffect } from "react";
import { useState } from "react";

const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(() => {
        if (typeof window == "undefined") {
            return false;
        }

        return matchMedia(query).matches;
    });

    useEffect(() => {
        if (typeof window == "undefined") {
            return;
        }

        const mediaQueryList = matchMedia(query);

        const onChange = () => {
            setMatches(mediaQueryList.matches)
        };

        try {
            mediaQueryList.addEventListener("change", onChange);
        }
        catch {
            mediaQueryList.addListener(onChange);
        }

        return () => {
            try {
                mediaQueryList.removeEventListener("change", onChange);
            }
            catch {
                mediaQueryList.removeListener(onChange);
            }
        }
    });

    return matches;
};

export default useMediaQuery;
