import React from "react"

import { Provider } from "react-redux"
import { ReactNode } from "react"

import { createStore } from "redux";

import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import menuReducer from "@Js/Reducer/menuReducer";

type RootWrapperProps = Readonly<{
    element: ReactNode
}>;

const RootWrapper = (props: RootWrapperProps) => {
    const {
        element
    } = props;

    const reducer = combineReducers({
        menu: menuReducer
    });

    const enhancer = composeWithDevTools();

    const store = createStore(reducer, enhancer);

    return (
        <Provider store={ store }>
            { element }
        </Provider>
    );
}

export default RootWrapper;
